import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    svgHover: {
        fill: theme.palette.foreground.default,
        "&:hover": {
            fill: theme.palette.primary.main,
        },
        transition: "all 0.5s ease",
    },
}));

export const Logo = () => {
    const classes = useStyles();

    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 500 500"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.svgHover}
      >
        <path d="M193.815,290.857L174.692,350.386C174.692,350.386 160.323,389.751 159.063,393.845C154.654,395.735 149.458,396.68 143.474,396.68C138.435,396.68 133.947,395.341 130.011,392.664C126.074,389.987 124.106,385.657 124.106,379.673C124.106,376.839 124.578,373.926 125.523,370.934C126.468,367.942 127.57,364.714 128.83,361.25C133.554,348.023 139.616,331.016 147.017,310.231C154.418,289.445 162.449,267.321 171.109,243.859C179.77,220.396 188.588,196.776 197.564,172.999C206.539,149.222 214.806,127.885 222.365,108.989C224.884,107.729 228.663,106.47 233.702,105.21C238.741,103.95 243.938,103.32 249.291,103.32C255.59,103.32 261.652,104.265 267.479,106.155C273.305,108.044 277.005,111.194 278.58,115.603C286.138,133.869 294.248,154.733 302.909,178.195C311.569,201.658 320.309,225.671 329.127,250.236C337.945,274.801 346.755,300.079 354.786,323.856L312.628,317.992L302.2,284.721C297.476,270.549 292.595,256.299 287.556,241.969C282.517,227.64 277.714,213.862 273.148,200.634C268.581,187.407 264.329,175.204 260.393,164.023C256.456,152.843 253.228,143.317 250.709,135.443L248.819,135.443C245.355,144.891 241.418,155.678 237.009,167.803C232.6,179.928 228.033,192.604 223.309,205.831C218.585,219.058 213.862,232.443 209.138,245.985C204.414,259.527 193.815,290.857 193.815,290.857Z" />
        <path d="M354.4,322.749C362.431,346.527 379.66,396.138 385.959,415.349C383.439,418.183 356.202,446.896 356.202,446.896C356.202,446.896 335.583,385.5 333.378,379.201L312.12,316.372L354.4,322.749Z" />
        <path d="M398.207,451.27C356.725,481.879 305.46,499.979 250,499.979C112.033,499.979 0.021,387.967 0.021,250C0.021,112.033 112.033,0.021 250,0.021C387.967,0.021 499.979,112.033 499.979,250C499.979,332.507 452.056,387.916 421.106,425.002C413.715,423.212 405.814,418.146 406.006,405.746C406.209,392.578 419.042,380.834 422.766,375.89C449.501,340.397 463.85,298.399 463.85,250C463.85,131.973 368.027,36.15 250,36.15C131.973,36.15 36.15,131.973 36.15,250C36.15,368.027 131.973,463.85 250,463.85C301.607,463.85 385.931,415.045 385.931,415.045L398.207,451.27Z" />
      </svg>
    );
};
